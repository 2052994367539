<template>
    <div class="box-page">
    </div>
</template>
<script>
    export default {
      async mounted() {
        var that = this;
        that.$router.push({path: that.$constants.URL_ERR.LOGIN});
      }
    }
</script>

<style lang="scss" scoped>
.box-page{display: block !important;
}
@media (max-width: 767px){
    .box-img{
        iframe{max-width: 100%;}
    }
}
</style>